import React from 'react'
import { TwitterShareButton, LinkedinShareButton } from 'react-share'
import ReactHtmlParser from 'html-react-parser'
import Title from '../../common/title'
import styles from './index.module.css'
import { useRouter } from 'next/router'
import BlogSocialIcon from '../../common/svgs/blog-social-icon'
import { formatBlogDate } from '../../../utils'
import Link from 'next/link'
import Image from 'next/image'

const userAvatar = '/assets/images/user.png'
const linkedLogo = '/assets/images/linkedin.png'

const BlogPost = ({ singlePost }) => {
  const router = useRouter()

  // Function to get the current blog post URL
  const getCurrentBlogPostUrl = () => {
    const baseUrl = 'https://relysia.com'
    const currentUrl = `${baseUrl}${router.asPath}`
    return encodeURIComponent(currentUrl)
  }

  // Function to get the share URL for Twitter
  const getTwitterShareUrl = () => {
    const text = encodeURIComponent(
      `Check out this blog post: ${singlePost.title}`,
    )
    const currentUrl = getCurrentBlogPostUrl()
    // return `${text}&url=${currentUrl}`;
    return `https://relysia.com${router.asPath}`
  }

  // Function to get the share URL for LinkedIn
  const getLinkedInShareUrl = () => {
    const title = encodeURIComponent(singlePost.title)
    const summary = encodeURIComponent(singlePost.excerpt) // You can use excerpt as the summary
    const currentUrl = getCurrentBlogPostUrl()
    return `https://www.linkedin.com/shareArticle?mini=true&url=${currentUrl}&title=${title}&summary=${summary}`
    // return `https://relysia.com${router.asPath}`
  }

  return (
    <>
      <div>
        <div className="flex flex-row mb-5 items-center">
          <Link href="/home">Home</Link>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>

          <span>Blog</span>
        </div>
        <h1 className={styles.header}>{singlePost.title}</h1>
        <div className={styles.authorWrapper}>
          <div className={styles.author}>
            <img className={styles.avatar} src={'/relysiateam.jpg'} alt={'Relysia Logo'} />
            <div className="flex flex-col">
              <span>Relysia Team</span>
              <span className="text-placeholder">
                {formatBlogDate(singlePost.date)}
              </span>
            </div>
          </div>
          <div className={`${styles.icons} px-2`}>
            {/* Twitter Share Button */}
            <TwitterShareButton
              className={`${styles.icons} px-2`}
              url={getTwitterShareUrl()}
            >
              <BlogSocialIcon iconType="twitter" />
            </TwitterShareButton>

            {/* LinkedIn Share Button */}
            <LinkedinShareButton
              className={`${styles.icons} px-2`}
              url={getLinkedInShareUrl()}
            >
              <BlogSocialIcon iconType="linkedin" />
              {/* <img
                // src="/assets/images/linkedin.png"
                src='https://cms.vaionex.com/bitcoinsv_io/wp-content/uploads/2023/10/linkedin.png'
                style={{
                  backgroundImage:
                    'linear-gradient(to bottom right, #E70060, #FC8F0C)',
                  backgroundSize: 'cover',
                  width: '100%',
                  height: '100%',
                }}
                alt="LinkedIn Logo"
              /> */}
            </LinkedinShareButton>
          </div>
        </div>
        <div className={styles.imgWrapper}>
          {/* <image
          className={styles.img}
          src={`https:${image}`}
          alt={title}
          width={700}
          height={700}
          layout="responsive"
        /> */}
        </div>
        <div className="blog-post-content prose lg:prose-xl">
          {ReactHtmlParser(singlePost.content)}
        </div>
      </div>
    </>
  )
}

export default BlogPost
